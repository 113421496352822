.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-link {
  color: #61dafb;
}

.container {
  padding-left: 90px;
}

.zapier-bot-section {
  padding-left: 20px; /* Extra padding */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}